const config = {
  applicationName: 'iCoordinator &trade;',
  brand: 'icoordinator',
  copyrightName: '&copy; Designtech Solutions AB',
  applicationVersion: '3.0.6',
  apiServerDomain: process.env.REACT_APP_apiServerDomain,
  oAuthClientId: 'icoordinator_web',
  oAuthGrantType: 'password',
  oAuthRefreshTokenGrantType: 'refresh_token',
  oAuthTokenEndpoint: '/auth/token',
  imageResources: 'resources/images/',
  languageResources: 'locale/',
  languageResourceType: '.json',
  serverDateFormat: 'Y-m-d\\TH=i=sO',
  defaultDateFormat: 'Y-m-d',
  defaultTimeFormat: 'H:i:s',
  BASE_URL: process.env.REACT_APP_BASE_URL,
  resetPasswordEndpoint: '/users/reset-password',
  cachNamePortel: 'MY_PORTEL',
  cacheLang: 'My_LANGUAGE',
  WEATHER_APP_KEY: 'e3c55ad24d104b8c940215558220406',
  WEATHER_APP_URL: 'https://api.weatherapi.com/v1/current.json?aqi=no',
  cachePortalMembersHeader: 'Portal_TableHead',
  cacheInviteTableHeader: 'Invite_Table_Head',
  driveTableHeader: 'drive_table_header',
  cacheTableMemberHeader: 'Member_TableHead',
  cacheWorkspaceTableHeader: 'Workspace_TableHead',
  cacheLabelTableHeader: 'Label_TableHead',
  cacheGroupTableHeader: 'Group_TableHead',
  Language_Types: ['da', 'en', 'fr', 'it', 'no', 'pl', 'sv', 'zh'],
  cacheFavoriteHomeFolder: 'Favorite_Folder',
  cacheFavoriteSmartFolder: 'Favorite_SmartFolder',
  IccordinatorAppCache: {
    acceptcookiescache: 'Cookies_Accept',
    drivesidebarcache: 'Drive_Sidebar_Data',
    peoplesidebarcache: 'People_Sidebar_Data',
    adminsidebarcache: 'Admin_Sidebar_Data',
  },
  NODE_API_URL: process.env.REACT_APP_NODE_API_URL,
  WEATHER_ORDINARY: 'https://api.openweathermap.org/data/2.5/weather',
  WEATHER_MAP_API_URL: 'https://maps.openweathermap.org/maps/2.5/weather',
  WEATHER_KEY: '1cc48e2dbfbb725834cf119d07d9e48a',
  WEATHER_CITY: 'Lulea',
  THRESHOLD_CHUNK_SIZE: 1024 * 1024 * 10,
  CHUNK_SIZE: 5242880,
  APP_VERSION: '3.0.7',
  WORKSPACE_COPY : 'Workspace copy',
  FOLDER_COPY : 'Folder copy',
  RECEIVED_NOTIFICATION : 'have recieved an invitation to',
  INITIATED : 'INITIATED',
  COMPLETED : 'COMPLETED'
};
export default config;
